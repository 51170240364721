<header class="container-fluid gradient-primary shadow-1 px-md-4">
    <div class="row py-2 py-md-5" data-aos="fade-up" data-aos-duration="2000">
        <div class="col-12 col-md-6 col-lg-7 p-5">
            <div class="text-white ms-md-5">
                <h1 class="display-4 fw-medium pb-2">Transformando la Salud Mental con Inteligencia Artificial</h1>
                <h2 class="display-6">Soluciones innovadoras para la detección y manejo del TDAH</h2>
                <hr class="text-white">
                <p class="fs-4 fw-light">
                    Descubre la vanguardia en análisis de salud mental con <b>NeuroDetectAI</b>. Nuestro enfoque basado en IA proporciona una 
                    detección precisa y temprana de condiciones como el [...], apoyando tanto a especialistas como a pacientes en el 
                    camino hacia el bienestar. Experimenta una nueva dimensión en la atención de la salud mental.
                </p>
                <a class="btn btn-light border-2 shadow rounded-5 px-4 py-2 mt-2 fs-5 fw-semibold" href="#">
                    Saber más
                </a>
                <a class="btn btn-outline-light border-2 shadow rounded-5 px-4 py-2 ms-3 mt-2 fs-5 fw-semibold">
                    Contacto
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 py-md-5 pe-md-5 d-flex">
            <div class="row align-items-center mb-5 mb-md-0 pe-md-5">
                <img src="assets/images/projects/dtdah/2.png" class="img-fluid rounded-2" alt="project-ss">
            </div>
        </div>
    </div>
</header>