<section class="container-fluid gradient-thirdty shadow-1 p-5">
    <div class="p-4 p-md-5 m-md-5 bg-white rounded-4 shadow">
        <div class="col px-md-5 py-4 text-center" data-aos="fade-up" data-aos-duration="1500">
            <div class="mb-3">
                <img src="assets/images/solutions.png" class="img-fluid text-center" style="width: 8rem;" alt="neurodetecai_logo">
            </div>
            <div class="row">
                <h2 class="display-5 fw-medium text-head-blue-1">Nuestras Soluciones</h2>
                <p class="text-p-1 fs-4 my-3">
                    <b>NeuroDetectAI</b> es una plataforma que ofrece software potenciado por inteligencia
                    artificial con el objetivo de detectar
                    trastornos del neurodesarrollo a través de la interpretación de diferentes biomarcadores y la
                    experiencia médica.
                </p>
            </div>
        </div>

        <hr class="featurette-divider mx-md-5">

        <div class="row d-flex align-items-center px-md-5"  data-aos="fade-right" data-aos-duration="1500">
            <div class="col-md-8">
                <h2 class="display-6 fw-medium text-head-blue-1">DETEC-TDAH</h2>
                <p class="lead">
                    Aplicación Web para la identificación del nivel del Trastorno de Déficit de Atención e Hiperactividad, 
                    DETEC-TDAH es una herramienta sólida y práctica para el proceso de identificación de pacientes con TDAH; 
                    cuyo objetivo principal es identificar pacientes con el Trastorno por Déficit de Atención e Hiperactividad 
                    con técnicas de IA mediante navegadores web.
                </p>
                <a href="/detec-adhd" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-3 fs-5 fw-semibold">
                    Saber más
                </a>
            </div>
            <div class="col-md-4 py-3">
                <img src="assets/images/projects/dtdah/1.png" class="img-fluid rounded-3 shadow" alt="detec-tdah">
            </div>
        </div>

        <hr class="featurette-divider mx-md-5">

        <div class="row featurette d-flex align-items-center text-end px-md-5" data-aos="fade-left" data-aos-duration="1500">
            <div class="col-md-8 order-md-2">
                <h2 class="display-6 fw-medium text-head-blue-1">FOCUSVR</h2>
                <p class="lead">
                    FOCUSVR es una innovadora aplicación de realidad virtual diseñada para mejorar la concentración en niños con TDAH. Utiliza técnicas de atención sostenida y entornos virtuales interactivos para ayudar a los niños a desarrollar habilidades de concentración, mejorar su rendimiento escolar y sus habilidades de comunicación.
                </p>
                <a href="/focusvr" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-3 fs-5 fw-semibold">
                    Saber más
                </a>
            </div>
            <div class="col-md-4 order-md-1 py-3">
                <img src="assets/images/projects/focusvr/1.jpg" class="img-fluid rounded-3 shadow" alt="detec-tdah">
            </div>
        </div>

        <hr class="featurette-divider mx-md-5">

        <div class="row featurette d-flex align-items-center px-md-5" data-aos="fade-right" data-aos-duration="1500">
            <div class="col-md-8">
                <h2 class="display-6 fw-medium text-head-blue-1">Happy Shop</h2>
                <p class="lead">
                    Happy Shop es una aplicación diseñada para mejorar el nivel de concentración en niños con Trastorno por Déficit de Atención e Hiperactividad (TDAH) utilizando técnicas de memoria. A través de actividades lúdicas que fortalecen la memoria de trabajo, Happy Shop ofrece una herramienta efectiva y motivadora para apoyar el tratamiento de niños con TDAH, facilitando la terapia de manera divertida y accesible.
                </p>
                <a href="/hshop" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-3 fs-5 fw-semibold">
                    Saber más
                </a>
            </div>
            <div class="col-md-4 order-md-1 py-3">
                <img src="assets/images/projects/hshop/1.jpg" class="img-fluid rounded-3 shadow" alt="detec-tdah">
            </div>
        </div>

        <hr class="featurette-divider mx-md-5">

        <div class="row featurette d-flex align-items-center text-end px-md-5" data-aos="fade-left" data-aos-duration="1500">
            <div class="col-md-8 order-md-2">
                <h2 class="display-6 fw-medium text-head-blue-1">VirTDAH</h2>
                <p class="lead">
                    VirTDAH es una aplicación innovadora diseñada para mejorar el nivel de concentración en niños con Trastorno por Déficit de Atención e Hiperactividad (TDAH) utilizando técnicas de coordinación. A través de actividades de clasificación de objetos, VirTDAH ayuda a los niños a desarrollar habilidades de organización y atención, contribuyendo significativamente a su desarrollo cognitivo y facilitando un tratamiento accesible y efectivo.
                </p>
                <a href="/virtdah" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-3 fs-5 fw-semibold">
                    Saber más
                </a>
            </div>
            <div class="col-md-4 order-md-1 py-3">
                <img src="assets/images/projects/virtdah/1.jpg" class="img-fluid rounded-3 shadow" alt="detec-tdah">
            </div>
        </div>

        <hr class="featurette-divider mx-md-5">

        <div class="row featurette d-flex align-items-center px-md-5" data-aos="fade-right" data-aos-duration="1500">
            <div class="col-md-8">
                <h2 class="display-6 fw-medium text-head-blue-1">LinguaTEA</h2>
                <p class="lead">
                    LinguaTEA es un innovador videojuego de realidad virtual (VR) para mejorar las habilidades del coordinación y de motricidad en niños con TEA. A través de actividades interactivas, MoviLand es un mundo imaginario enfocado en el movimiento y la motricidad fina.
                </p>
                <a href="/vtea" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-3 fs-5 fw-semibold">
                    Saber más
                </a>
            </div>
            <div class="col-md-4 order-md-1 py-3">
                <img src="assets/images/projects/vtea/1.jpg" class="img-fluid rounded-3 shadow" alt="detec-tdah">
            </div>
        </div>
    </div>
</section>