<footer class="container-fluid gradient-secondary pt-5 pb-1 px-5">
    <div class="row px-md-5 mx-md-5">
        <div class="col-6 col-md-2 mb-3 text-white fs-5">
            <h5 class="fs-4 fw-semibold">Enlaces</h5>
            <ul class="nav flex-column">
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">Inicio</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">Nosotros</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">Testimonios</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">FAQs</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">Contacto</a></li>
            </ul>
        </div>

        <div class="col-6 col-md-2 mb-3 text-white fs-5">
            <h5 class="fs-4 fw-semibold">Soluciones</h5>
            <ul class="nav flex-column">
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">Detec-TDAH</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">FocusVR</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">hShop</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">VirTDAH</a></li>
                <li class="nav-item mb-2"><a href="" class="nav-link p-0 text-white">MoviLand</a></li>
            </ul>
        </div>

        <div class="col-12 col-md-4 mb-3 text-white fs-5">
            <h5 class="fs-4 fw-semibold">Dirección</h5>
            <p class="mb-2">Avenida Oriente 9 Núm. 852, Colonia Emiliano Zapata, C.P. 94320 Orizaba, Ver.</p>
            <iframe class="rounded-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.7059692355924!2d-97.10092947221047!3d18.85573717663723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c502aeb2d35fed%3A0xba5f3840b0862949!2sTecNM%20-%20Campus%20Orizaba!5e0!3m2!1ses-419!2smx!4v1731468122817!5m2!1ses-419!2smx" 
                width="500" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div class="col-12 col-md-4 mb-3">
            <h5 class="fs-4 fw-semibold text-white mb-3">Mantente Informado</h5>
            <a class="twitter-timeline" data-lang="es" data-height="360" data-theme="dark" href="https://twitter.com/NeurologyToday?ref_src=twsrc%5Etfw">Tweets by NeurologyToday</a>
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div>
    </div>

    <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 mt-4 border-top text-white fs-5">
        <p class="fw-medium">© 2024 NeuroDetectAI, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
            <i class="bi bi-facebook fs-4 ms-3"></i>
            <i class="bi bi-twitter-x fs-4 ms-3"></i>
            <i class="bi bi-linkedin fs-4 ms-3"></i>
            <i class="bi bi-instagram fs-4 ms-3"></i>
        </ul>
    </div>
</footer>