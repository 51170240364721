<section class="container-fluid p-md-5">
    <div class="px-2 py-5 px-md-5 m-md-5">
        <div class="row text-center">
            <div class="col">
                <div class="row text-center">
                    <h2 class="display-5 fw-medium text-head-blue-1">Elige tu Plan</h2>
                    <p class="text-p-1 fs-4 my-3">
                        Encuentra el plan perfecto que se ajuste a tus necesidades y las de tu equipo. Desde soluciones
                        individuales hasta paquetes integrales, tenemos un plan para cada paso de tu viaje en la salud
                        mental
                        y el bienestar.
                    </p>
                </div>
                <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
                    <div class="col" data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                        <div class="card mb-4 rounded-4 shadow mx-2">
                            <div class="card-header py-3">
                                <h4 class="my-0 fw-normal">Básico</h4>
                            </div>
                            <div class="card-body">
                                <h1 class="display-6 pricing-card-title">
                                    <b>$29</b><small class="text-body-secondary fw-light">/mes</small>
                                </h1>
                                <ul class="list-unstyled my-4 fs-5">
                                    <li>1 Solución</li>
                                    <hr class="mx-5 my-2">
                                    <li>Soporte estándar</li>
                                    <hr class="mx-5 my-2">
                                    <li>1 Usuario</li>
                                    <hr class="mx-5 my-2">
                                    <li>5 GB de almacenamiento</li>
                                </ul>
                                <button type="button" class="btn btn-light border-2 shadow rounded-5 px-4 py-2 my-2 fs-5 fw-semibold">
                                    Saber más
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                        <div class="card mb-4 rounded-4 shadow mx-2">
                            <div class="card-header py-3">
                                <h4 class="my-0 fw-normal">Profesional</h4>
                            </div>
                            <div class="card-body">
                                <h1 class="display-6 pricing-card-title">
                                    <b>$59</b><small class="text-body-secondary fw-light">/mes</small>
                                </h1>
                                <ul class="list-unstyled my-4 fs-5">
                                    <li>Hasta 5 soluciones</li>
                                    <hr class="mx-5 my-2">
                                    <li>Soporte prioritario</li>
                                    <hr class="mx-5 my-2">
                                    <li>Hasta 10 Usuarios</li>
                                    <hr class="mx-5 my-2">
                                    <li>50 GB de almacenamiento</li>
                                </ul>
                                <button type="button" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-2 fs-5 fw-semibold">
                                    Saber más
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col" data-aos="fade-left" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                        <div class="card mb-4 rounded-4 shadow mx-2" style="border: #006bb3 2px solid;">
                            <div class="card-header rounded-top-4 py-3 gradient-primary text-white border-primary">
                                <h4 class="my-0 fw-normal">Empresarial</h4>
                            </div>
                            <div class="card-body">
                                <h1 class="display-6 pricing-card-title">
                                    <b>$99</b><small class="text-body-secondary fw-light">/mes</small>
                                </h1>
                                <ul class="list-unstyled my-4 fs-5">
                                    <li>Soluciones ilimitadas</li>
                                    <hr class="mx-5 my-2">
                                    <li>Soporte dedicado</li>
                                    <hr class="mx-5 my-2">
                                    <li>Usuarios ilimitados</li>
                                    <hr class="mx-5 my-2">
                                    <li>Almacenamiento Ilimitado</li>
                                </ul>
                                <button type="button" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 my-2 fs-5 fw-semibold">
                                    Saber más
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col">
                        <p class="text-p-1 fs-4 mt-3">
                            ¿Necesitas algo más personalizado? <a href="#" class="link-format">Contáctanos</a> para un plan personalizado.
                            <br>
                            ¿Tienes preguntas? Revisa nuestras <a href="#" class="link-format">Preguntas Frecuentes</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>