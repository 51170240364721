import { Component, AfterViewInit  } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.loadTwitterWidget();
  }

  loadTwitterWidget(): void {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.charset = 'utf-8';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
