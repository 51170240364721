<section class="container-fluid p-md-5">
    <div class="px-2 py-5 px-md-5 m-md-5">
        <div class="row text-center">
            <div class="col">
                <div class="row text-center">
                    <h2 class="display-5 fw-medium text-head-blue-1">Experiencias con NeuroDetectAI</h2>
                    <p class="text-p-1 fs-4 my-3">
                        Descubre las experiencias de nuestros usuarios y cómo nuestros servicios han transformado sus 
                        vidas. Desde mejoras en su bienestar mental hasta un mayor equilibrio emocional, estas 
                        historias reflejan el impacto positivo que hemos tenido en su día a día.
                    </p>
                </div>
                <div class="row pb-5 mt-5">
                    <div class="col">
                        <div class="main-carousel" data-flickity='{ "cellAlign": "center", "wrapAround": true, "autoPlay": 2000, "pauseAutoPlayOnHover": false}'>
                            <div class="carousel-cell" *ngFor="let item of items; let i = index" [class.active]="i === 0" style="height: 18rem;">
                                <div class="card p-4 mx-3 mx-md-4 rounded-4 shadow" style="width: 25rem;">
                                    <div class="mb-2">
                                        <img src="{{ item.imgSrc }}" alt="avatar" class="rounded-5" width="22%" alt="avatar">
                                    </div>
                                    <div class="flex-grow-1 pt-md-3">
                                        <span class="fs-5 fw-medium">“{{ item.title }}”</span>
                                    </div>
                                    <div class="avatar-author d-block mt-2">
                                        <span class="fs-6 fw-semibold">{{ item.name }}</span>
                                        <br>
                                        <span class="fs-6">{{ item.job }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>