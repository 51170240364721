<nav class="navbar navbar-expand-md sticky-top gradient-primary border-bottom border-3 border-white px-5 fw-medium" data-bs-theme="dark" style="position: block;">
    <div class="container-fluid">
        <a class="navbar-brand d-flex align-items-center" href="/">
            <img src="assets/images/logo/logo.png" alt="NeuroDetectAI" width="35" class="img-fluid me-2">
            <span class="fs-3">NeuroDetectAI</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav fs-5 px-2">
            <li class="nav-item light-item">
                <a class="nav-link active" href="/">Inicio</a>
            </li>
            <li class="nav-item light-item">
                <a class="nav-link active" href="#" (click)="scrollToSection('aboutus', $event)">Nosotros</a>
            </li>
            <li class="nav-item light-item dropdown">
                <a class="nav-link active dropdown-toggle" href="services" data-bs-toggle="dropdown" aria-expanded="false">Soluciones</a>
                <ul class="dropdown-menu bg-white shadow">
                    <li><a class="dropdown-item text-p-1" href="/detec-adhd">DETEC-TDAH</a></li>
                    <li><a class="dropdown-item text-p-1" href="/focusvr">FocusVR</a></li>
                    <li><a class="dropdown-item text-p-1" href="/hshop">hShop</a></li>
                    <li><a class="dropdown-item text-p-1" href="/virtdah">VirTDAH</a></li>
                    <li><a class="dropdown-item text-p-1" href="/vtea">MoviLand</a></li>
                    <hr class="dropdown-divider">
                    <li><a class="dropdown-item text-p-1" href="/neuroreport/login" target="_blank">NeuroReport Tool</a></li>
                </ul>
              </li>
            <!-- <li class="nav-item light-item">
                <a class="nav-link active"  href="#" (click)="scrollToSection('pricing', $event)">Planes</a>
            </li> -->
            <li class="nav-item light-item">
                <a class="nav-link active" href="#" (click)="scrollToSection('testimonials', $event)">Testimonios</a>
            </li>
            <li class="nav-item light-item">
                <a class="nav-link active" href="#" (click)="scrollToSection('faq', $event)">FAQ</a>
            </li>
            <li class="nav-item light-item">
                <a class="nav-link active" href="#" (click)="scrollToSection('contactus', $event)">Contacto</a>
            </li>
            <div class="vr mx-2 bg-light"></div>
            <li class="nav-item light-item dropdown">
                <a class="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-translate me-2"></i>Idioma
                </a>
                <ul class="dropdown-menu bg-white shadow">
                    <li><a class="dropdown-item text-p-1">Es</a></li>
                    <li><a class="dropdown-item text-p-1">En</a></li>
                    <li><a class="dropdown-item text-p-1">Fr</a></li>
                </ul>
            </li>
        </ul>
    </div>
</nav>