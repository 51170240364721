import { Component } from '@angular/core';
import { NavbarComponent } from './shared/layouts/navbar/navbar.component';
import { HeaderComponent } from './shared/layouts/header/header.component';
import { AboutUsComponent } from './shared/components/about-us/about-us.component';
import { ServicesComponent } from './shared/components/services/services.component';
import { TestimonialsComponent } from './shared/components/testimonials/testimonials.component';
import { PricingComponent } from './shared/components/pricing/pricing.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { FooterComponent } from './shared/layouts/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    NavbarComponent,
    HeaderComponent,
    AboutUsComponent,
    ServicesComponent,
    TestimonialsComponent,
    PricingComponent,
    FaqComponent,
    ContactUsComponent,
    FooterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Home';
}
