<app-navbar />
<main>
    <div id="home">
    <app-header />
    </div>
    <div id="aboutus">
        <app-about-us />
    </div>
    <div id="services">
        <app-services />
    </div>
    <!-- <div id="pricing">
        <app-pricing />
    </div> -->
    <div id="testimonials">
        <app-testimonials />
    </div>
    <div id="faq">
        <app-faq />
    </div>
    <hr class="mx-5">
    <div id="contactus">
        <app-contact-us />
    </div>
</main>
<app-footer />