import { Component } from '@angular/core';
import { ContactUsComponent } from '../../shared/components/contact-us/contact-us.component';

@Component({
    selector: 'app-vtea',
    standalone: true,
    imports: [
        ContactUsComponent
    ],
    templateUrl: './vtea.component.html',
    styleUrl: './vtea.component.scss'
})
export class VteaComponent {
    // Header
    title = "MoviLand";
    description = "MoviLand es una innovadora aplicación de realidad virtual diseñada para mejorar las habilidades de motricidad fina en niños con Trastorno del Espectro Autista (TEA). A través de escenarios interactivos basados en la vida real, MoviLand ayuda a los niños a desarrollar coordinación y destreza de manera cómoda y accesible."

    // Features
    subtitle_1 = "Desarrolla tus habilidades motoras con MoviLand";

    icon_feature_1 = "bi bi-hand-thumbs-up";
    feature_title_1 = "Entornos Realistas e Interactivos";
    description_feature_1 = "MoviLand utiliza escenarios inspirados en la vida cotidiana, diseñados específicamente para ayudar a los niños con TEA a sentirse cómodos y seguros, facilitando el aprendizaje y el desarrollo de habilidades de motricidad fina.";

    icon_feature_2 = "bi bi-controller";
    feature_title_2 = "Actividades Lúdicas y Guiadas";
    description_feature_2 = "Las actividades interactivas de MoviLand están diseñadas para trabajar en la coordinación mano-ojo, precisión, fuerza de agarre, y destrezas motoras, manteniendo a los niños motivados mientras se divierten.";

    icon_feature_3 = "bi bi-heart";
    feature_title_3 = "Apoyo Terapéutico Accesible";
    description_feature_3 = "MoviLand ofrece una solución accesible y efectiva para especialistas y familias, proporcionando herramientas tecnológicas avanzadas para el desarrollo motriz sin los elevados costos asociados a otros métodos."

    // Steps
    step_title_1 = "Regístrate";
    step_description_1 = "Crea una cuenta para acceder a MoviLand y comenzar a mejorar las habilidades de motricidad fina de los niños con TEA a través de experiencias inmersivas y educativas.";

    step_title_2 = "Configura tu Perfil";
    step_description_2 = "Gestiona los perfiles de los niños, incluyendo el historial de actividades y progresos, para asegurar un seguimiento detallado y personalizado.";

    step_title_3 = "Disfruta de MoviLand";
    step_description_3 = "Sumérgete en el mundo de MoviLand y ayuda a los niños a desarrollar habilidades motrices esenciales mientras se divierten en entornos diseñados especialmente para ellos."

    // Info
    info_title_1 = "Motricidad Fina y Diversión";
    info_description_1 = "MoviLand facilita la mejora de la motricidad fina en niños con TEA, utilizando ejercicios interactivos que trabajan en la coordinación, la precisión y la fuerza de manera entretenida y efectiva.";

    info_title_2 = "Tecnología Avanzada y Accesible";
    info_description_2 = "Con el uso de la realidad virtual, MoviLand proporciona una experiencia enriquecedora a un costo accesible, beneficiando a clínicas, hospitales y familias que buscan mejorar las habilidades de motricidad en niños con TEA."

    // Testimonials
    testimonial_title = "Lo que dicen nuestros usuarios";

    // Team
    team_title = "¿Quieres saber más?";
    team_description = "Descubre cómo MoviLand puede mejorar las habilidades motoras de los niños con TEA y brindarles una mejor calidad de vida. Visita nuestro blog para conocer más sobre las últimas investigaciones y avances en el tratamiento del TEA con realidad virtual."
    
    // Imágenes 
    imageTitle = "assets/images/projects/vtea/1.jpg";
    imageSteps = "assets/images/projects/vtea/4.jpg";
    imageInfo_1 = "assets/images/projects/vtea/2.jpg";
    imageInfo_2 = "assets/images/projects/vtea/5.png";
}
